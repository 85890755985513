import React from "react";
import serviceS1 from "@/images/marketing.jpg";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Marketing dixhital</h2>
      <p>
        Në Solxk ne mund ta bëjmë më të lehtë për bizneset që të lulëzojnë në
        Zona dixhitale. Përdorni profesionistët tanë të marketingut dixhital
        strategji të drejtuara nga të dhënat, përmbajtje inovative dhe
        progresive Teknologji për të nxitur rritjen, për të rritur dukshmërinë e
        Brandoni dhe angazhoni audiencën tuaj të synuar Ne pretendojmë se a
        Marketingu dixhital i suksesshëm e kapërcen vetëm audiencën arritja;Ka
        të bëjë me krijimin e lidhjeve kuptimplote që kanë të bëjnë merrni një
        masë. Qoftë përmes optimizimit të motorëve të kërkimit (SEO), sociale
        Marketingu mediatik ose fushatat me email përshtatin qasjen tonë ndaj
        për t'u lidhur me qëllimet dhe objektivat specifike të biznesit tuaj.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Planifikimi dhe Strategjia</h3>
          <p>Shërbimet që ne ofrojmë:</p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Optimizimi i Motorit të
              Kërkimit (SEO)
            </li>
            <li>
              <i className="fa fa-check-square"></i>Pay-per-Click (PPC) Reklama
            </li>
            <li>
              <i className="fa fa-check-square"></i>Pay-Per-Click (PPC)
              Advertising
            </li>
            <li>
              <i className="fa fa-check-square"></i>Marketingu i përmbajtjes &
              Analitikë dhe raportim
            </li>
            <li>
              <i className="fa fa-check-square"></i>Marketing me email
            </li>
          </ul>
        </div>
      </div>
      <p>
        Pavarësisht nëse jeni një startup, keni një identitet të fortë marke set
        ose një kompani e themeluar që synon tuaj Për të përditësuar praninë
        tuaj vizuale, Solxk është këtu për t'ju ndihmuar për të ndihmuar. Na
        kontaktoni sot për të diskutuar dizajnin tuaj nevojave dhe hidhni hapin
        e parë për të transformuar markën tuaj në një transformuar kryevepër
        vizuale.
      </p>
      <p>
        Në Solxk ne e pranojmë se themeli i një suksesi Projekti i zhvillimit
        të Web-it qëndron në planifikimin e përpiktë.Përpara Shkrimi i një
        rreshti të vetëm kodi ose dizajnimi i një piksel të vetëm ne investojmë
        kohë për të kuptuar qëllimet e biznesit tuaj, Grupet e synuara dhe
        kërkesat tona gjithëpërfshirëse Procesi i planifikimit siguron që
        aspekti i projektit tuaj të trajtohet me kujdes merren parasysh dhe
        ekzekutohen me saktësi.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
