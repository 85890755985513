import React, { Fragment } from "react";

const ServiceSidebar = () => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title">Shërbime</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <a href="/website-development">Zhvillimi i faqes në internet</a>
            </li>
            <li>
              <a href="/website-graphics">Dizajn grafik</a>
            </li>
            <li>
              <a href="/digital-marketing">Marketing dixhital</a>
            </li>
            <li>
              <a href="/cyber-security">Siguria kibernetike</a>
            </li>
            <li>
              <a href="/app-development">Zhvillimi i Aplikacionit</a>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help">
          <h4>A keni nevojë për Solxk?</h4>
          <p>
            Preferoni të flisni me një njeri sesa të plotësoni një formular?
            telefononi zyrën e korporatës dhe ne do t'ju lidhim me një anëtar të
            ekipit që mund t'ju ndihmojë.
          </p>
          <h2>049 000 000</h2>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
